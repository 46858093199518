export function nav() {
  const trigger = document.getElementById('nav-trigger');
  if(trigger) {
    let scTop = 0;
    
    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      if(document.body.classList.contains('is-nav-active')) {
        document.body.classList.remove('is-nav-active');
        window.scrollTo(0, scTop);
      } else {
        scTop = document.documentElement.scrollTop || document.body.scrollTop;
        document.body.classList.add('is-nav-active');
      }
      return false;
    });
  }

  const overLay = document.getElementById('nav-overlay');
  if(overLay) {
    overLay.addEventListener('click', (e) => {
      e.preventDefault();
      document.body.classList.remove('is-nav-active');
    });
  }
}