// Stylesheets
import './assets/scss/site.scss';

import {fixSvgIntrinsicSizing} from './assets/modules/fixSvgIntrinsicSizing';
import {hover} from './assets/modules/hover';
import {nav} from './assets/modules/nav';
import {nestNav} from './assets/modules/nestNav';
import {localNav} from './assets/modules/localNav';
//import {langnav} from './assets/modules/langnav';
//import {modal} from './assets/modules/modal';
import {lazyload} from './assets/modules/lazyload';
import {carousel} from './assets/modules/carousel';
import {initPhotoSwipeFromDOM} from './assets/modules/photoswipe-init';
import {Pjax} from 'pjax-api';

const init = () => {
  fixSvgIntrinsicSizing();
  hover();
  nestNav();
  localNav();
  lazyload();
  carousel();
  initPhotoSwipeFromDOM('.js-pswp');
}

nav();
init();

new Pjax({
  areas: [
    '#main,#nav,#footer',
    'body'
  ],
  update: {
    css: false,
    script: false
  }
});
 
document.addEventListener('pjax:ready', function(e) {
  document.body.classList.remove('is-nav-active');
  FONTPLUS.reload(false);
  init();
  gtag('config', ga_code, {'page_path' : location.pathname});
});