export function localNav() {
  const baseElement = document.querySelector('.js-local-nav-container');
  const fixedElement = document.querySelector('.js-local-nav-sticky');

  if(baseElement) {
    function getOffset (el) {
      const box = el.getBoundingClientRect();
      return {
        top: box.top + window.pageYOffset - document.documentElement.clientTop,
        left: box.left + window.pageXOffset - document.documentElement.clientLeft
      }
    }

    let baseElementOffsetTop = '';

    const setSize = () => {
      const baseElementOffset = getOffset(baseElement);
      baseElementOffsetTop = baseElementOffset.top;
    }

    const scroller = () => {
      const scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      if(baseElementOffsetTop <= scTop) {
        fixedElement.classList.add('is-sticky');
      } else {
        fixedElement.classList.remove('is-sticky');
      }
    }

    window.addEventListener('resize', () => {
      setSize();
      scroller();
    });

    window.addEventListener('scroll', scroller);

    setSize();
    scroller();
  }
}