export function nestNav() {
  const closest = (el, targetClass) => {
    for (let item = el; item; item = item.parentElement) {
      if (item.classList.contains(targetClass)) {
        return item
      }
    }
  }
  const nav = document.querySelectorAll('.parent-nav');
  const parentLink = document.querySelectorAll('.parent-link');
  
  if(!IS_TOUCH) {
    for (let i = 0; i < nav.length; i++) {
      const el = nav[i];
      el.addEventListener('mouseenter', function(e) {
        this.classList.add('is-active');
      });
      el.addEventListener('mouseleave', function(e) {
        this.classList.remove('is-active');
      });
    }
  } else {
    for (let i = 0; i < parentLink.length; i++) {
      const el = parentLink[i];
      if(window.innerWidth >= 1024) el.setAttribute('target', '_self');
      el.addEventListener('click', function(e) {
        e.preventDefault();
        const parent = closest(el, 'parent-nav');
        parent.classList.toggle('is-active');
        return false;
      });
    }
  }
}